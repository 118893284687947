.home_root_container {
  max-width: 1200px;
  margin: auto;
}

.menu_title_table_data_photo {
  border-style : hidden!important;
  padding-left: 18px !important;
}

.menu_title_table_data_info_column {
  vertical-align:top;
  padding-top: 10px;
  border-style : hidden!important;
  width: 100%;
}

.menu_title_table_options_column {
  vertical-align:middle; 
  padding-top: 10px;
  border-style : hidden!important;
}

.menu_title_photo {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  border: 4px solid #F5F5F5;
  object-fit: cover;
  margin-left: 0px;
  margin-top: 20px;
}

.menu_title_name_first_letter {
  background-color: rgba(26,26,26,1);
  color: #ffffff;
  width: 60px;
  height: 60px;
  font-size: 18.72px;
  font-weight: 800;
  margin-top: 20px;
  margin-left: 0px;
  line-height: 60px;
  text-align: center;
  border-radius: 30px;
  border: 0px solid #ffffff !important;
}


td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }

.menu_name {
  color: rgba(26,26,26,1);
  font-size: 24px;
  font-weight:700;
  margin-left: 0px;
  margin-top: 0px;
}

.welcome_text {
  color: rgba(26,26,26,1);
  font-size: 13.3px;
  font-weight:400;
  margin-left: 0px;
  margin-top: 25px;
  margin-right: 20px;
}

.view_menu_detail_button {
  background-color: transparent;
  border-color: transparent;
  float: right;
  height: 30px;
  color: rgba(26,26,26,1);
  font-size: 12px;
  font-weight:700;
  line-height: 16.39px;
}



@media screen and (min-width: 1000px) {
  .view_menu_detail_button {
    background-color: #F5F5F5;
    border-radius: 22px;
    width: 89px;
    height: 44px;
  }
}

.home_sheet {
  max-width: 800px;
  margin: auto;
}

.home_menu_banner {
  width: 100%;
  height: 320px;
  border-radius: 8px;
  object-fit: cover;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 20px;
  margin-bottom: 0px;
}

