.menu_detail_parent_container {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  /*  overflow: auto;
    max-height: 100vh;  */
}

.menu_detail_banner {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 30px;
}

.menu_detail_logo {
    color: rgba(102,102,102,1);
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-top: -65px;
    margin-left: 15px;
    line-height: 55px;
    text-align: center;
    border-radius: 32px;
    border: 4px solid #ffffff !important;
    position:absolute;
    z-index: 200;
}

.menu_detail_logo_for_no_banner {
    color: rgba(102,102,102,1);
    width: 64px;
    height: 64px;
    object-fit: cover;
    margin-top: 0px;
    margin-left: -15px;
    margin-right: -10px;
    line-height: 55px;
    text-align: center;
    border-radius: 32px;
    border: 4px solid #ffffff !important;
}

.menu_detail_title {
    color: rgba(26,26,26,1);
    font-size: 24px;
    font-weight:700;
    margin-top: 0px;
    margin-bottom: 16px;
}

.menu_detail_description {
    color: rgba(102,102,102,1);
    font-size: 13.3px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 18.17px;
}

.menu_detail_name_first_letter {
    background-color: rgba(26,26,26,1);
    color: #ffffff;
    width: 64px;
    height: 64px;
    font-size: 18.72px;
    font-weight: 800;
    margin-top: -65px;
    margin-left: 15px;
    line-height: 55px;
    text-align: center;
    border-radius: 32px;
    border: 4px solid #ffffff !important;
    position:absolute;
    z-index: 200;
}

.menu_detail_name_first_letter_for_no_banner {
    background-color: rgba(26,26,26,1);
    color: #ffffff;
    width: 64px;
    height: 64px;
    font-size: 18.72px;
    font-weight: 800;
    margin-top: 0px;
    margin-left: -15px;
    margin-right: -10px;
    line-height: 55px;
    text-align: center;
    border-radius: 32px;
    border: 4px solid #ffffff !important;
}

.menu_detail_find_us {
    color: rgba(26,26,26,1);
    font-size: 12px;
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 18.17px;
}

.menu_detail_title_table_data_photo {
    border-style : hidden!important;
}

.menu_detail_title_table_data_info_column {
vertical-align:middle;
padding-top: 20px;
border-style : hidden!important;
width: 100%;
}


.menu_detail_no_banner_title_table {
    margin-top: -10px;
}

.menu_detail_close_button {
    width: 140px;
    height: 35px;
    background-color: white;
    border: 2px solid #1A1A1A;
    border-radius: 8px;
    color: #1A1A1A;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.menu_detail_close_button_container {
    height: 35px;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

  @media screen and (min-width: 1000px) {
    .menu_detail_close_button_container {
        margin-bottom: 15px;
    }
    
  }
