.error_title {
    color: rgba(26,26,26,1);
    font-size: 28px;
    font-weight:600;
    margin-top: 80px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 20px;
    text-align: center;
}

.error_description {
    color: rgba(25,26,26,1);
    font-size: 16px;
    font-weight:300;
    margin-top: 0px;
    margin-left: 40px;
    margin-right: 40px;
    margin-bottom: 40px;
    text-align: center;
}