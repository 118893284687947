@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@100;400&display=swap');

body, html {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: white;
}

.body-wrap {
  position: relative;
  /*overflow: hidden; */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: auto;
  background-color: #ffffff;
}