.menu_item_desktop_container {
    max-width: 336.6px;
}

.menu_item_desktop_cell_photo {
    width: 100%;
    height: 233.2px;
    border-radius: 8px;
    object-fit: cover;
    background-color: gray;
}

.menu_item_desktop_info_row {
    vertical-align:top;
    padding-top: 10px;
    border-style : hidden!important;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px;
}