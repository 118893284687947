

.menu_item_cell_table_data_photo {
    border-style : hidden!important;
}

.menu_item_cell_table_data_info_column {
    vertical-align:top;
    padding-top: 10px;
    border-style : hidden!important;
    width: 100%;
}


.menu_item_cell_photo {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin-left: 10px;
    background-color: gray;
}

.menu_item_cell_title {
    color: #1A1A1A;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1; 
    -webkit-box-orient: vertical;
}

.menu_item_cell_description {
    color: rgba(102,102,102,1);
    font-size: 13.3px;
    font-weight: 400;
    height: 38px;
    line-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.menu_item_cell_price_and_view_more_parent_container {
    color: rgba(26,26,26,1);
    font-size: 16px;
    font-weight:700;
    line-height: 30px;
    margin-top: 12px;
}

.menu_item_cell_view_more_button {
    background-color: transparent;
    border-color: transparent;
    float: right;
    height: 30px;
    color: rgba(26,26,26,1);
    font-size: 12px;
    font-weight:700;
    line-height: 16.39px;
    cursor: pointer;
}

