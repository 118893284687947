

.section_title {
    color: #666666;
    font-weight: 800;
    font-size: 18.72px;
    line-height: 26px;
    margin-top: 40px;
    margin-left: 20px;
    margin-right: 10px;
    margin-bottom: 5px;
}

  @media screen and (min-width: 1000px) {
    .section_title {
        color: #1A1A1A;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
    }
    
  }

.desktop_grid_container {
    display: grid;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    row-gap: 30px;
    column-gap: 40px;
}


@media screen and (min-width: 0px) and (max-width: 1199px) {
    .desktop_grid_container {
        grid-template-columns: max-content max-content;
    }

  }
  @media screen and (min-width: 1200px) {
    .desktop_grid_container {
        grid-template-columns: max-content max-content max-content;
    }
    
  }


