.link_button {
    background-color: rgb(249, 249, 249);
    color: rgba(26,26,26,1);
    height: 36px;
    font-size: 13.28px;
    font-weight: 800;
    line-height: 18.14px;
    margin-top: 10px;
    margin-right: 10px;
    border-width: 0px;
    border-radius: 4px;
    padding: 5px 10px 5px 10px;
    cursor: pointer;
}

.link_button_icon {
    margin-right: 5px;
}

/* Add this to align vertically */
button > img,
button > span {
  vertical-align: middle;
}