.detail_parent_container {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
  /*  overflow: auto;
    max-height: 100vh;  */
}

.detail_photo {
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    object-fit: cover;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 10px;
}

.detail_title {
    color: rgba(26,26,26,1);
    font-size: 24px;
    font-weight:700;
    margin-top: 0px;
    margin-bottom: 16px;
}

.detail_description {
    color: rgba(102,102,102,1);
    font-size: 13.3px;
    font-weight: 400;
    margin-bottom: 20px;
    line-height: 18.17px;
}

.detail_price {
    color: rgba(26,26,26,1);
    font-size: 18.72px;
    font-weight: 700;
    margin-bottom: 20px;
}

.menu_item_detail_subsection_title {
    color: rgba(26,26,26,1);
    font-size: 12px;
    font-weight: 800;
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 18.17px;
}

.menu_item_detail_subsection_title_icon {
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

.menu_item_detail_subsection_title img, span{
    vertical-align: middle;
}

.menu_item_detail_divider{
    background-color: #f9f9f9;
    height: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
}

.menu_item_detail_close_image{
    width: 34px;
    height: 34px;
    margin-top: 10px;
    margin-right: 30px;
    cursor: pointer;
    background-color: transparent;
}

.menu_item_detail_close_image_for_no_photo{
    width: 34px;
    height: 34px;
    margin-top: 0px;
    margin-right: 30px;
    cursor: pointer;
    background-color: transparent;
}

.menu_item_detail_close_image_container{
    width: 100%;
    height: 34px;
    margin-left: auto; 
    margin-right: 0px;
    position:absolute;
    z-index: 200;
    background-color: transparent;
    display: flex; 
    justify-content: flex-end;
}

.menu_item_detail_close_button {
    width: 140px;
    height: 35px;
    background-color: white;
    border: 2px solid #1A1A1A;
    border-radius: 8px;
    color: #1A1A1A;
    font-weight: 800;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
}

.menu_item_detail_close_button_container {
    height: 35px;
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
    
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

  @media screen and (min-width: 1000px) {
    .menu_item_detail_close_button_container {
        margin-bottom: 15px;
    }
    
  }
