.footer {
    background: #1a1a1a;
   /* background-image: url('../../images/header_background.jpg');  */
    color: #fff;
    padding: 2rem 0;
    text-align: center;
    font: normal .8rem 'Montserrat';
    color: rgba(white,.4);
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
}

.footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer li {
    display: inline-block;
}

.footer a {
    display: block;
    padding: .4rem .7rem;
    font: normal .9rem 'Montserrat';
    text-decoration: none;
    color: rgba(255, 255, 255, 1);
}

.footer a:hover {
    color: rgb(214, 214, 214);
}